import React from "react";
import Slider from "../components/home/SliderHeroesOne";
import Slider2 from "../components/home/SliderComp3";
import { Link } from "gatsby";
// import { Helmet } from "react-helmet";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import VoucherAd from "../components/voucherAd";§
// import BackButton from "../components/backButton"
import SEO from "../components/seo";
import tw from "tailwind.macro";
import TopBar from "../components/Layout/TopBar";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Seo from "../components/seo";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import ReusableTiles from "../components/ReusableTiles";
import { css } from "@emotion/core";
import hero from '../images/comp/bruno-ellie-header.jpg';
import hero2 from '../images/comp/willow-jennifer-header.jpg';
import hero3 from '../images/comp/dora-header.jpg';
import hero4 from '../images/comp/scout-header-2023.jpg';
import hero5 from '../images/comp/winalot-vote-banner-640x140.gif';

// import "../scss/main.scss";

const Shortlist2023 = () => (
  // <Layout>
  <div>
    {/* <SEO title="Meet the Dog of the Year 2023 shortlist" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | Dog of the Year 2023 shortlist"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <Slider2 />

      <div>
        <div className="container">
          <div
            className="prose max-w-2xl mx-auto py-12"
            css={css`
              h1 {
                ${tw`text-primary font-slab font-bold text-center `}
              }
              h2 {
                ${tw`text-lightCopy font-sans font-bold text-center `}
              }

              .accent {
                ${tw`text-accent`}
              }
            `}
          >
            <h1 css={css`
                display: none !important;
                `}>Meet the Dog of the Year 2023 shortlist</h1>
            <h2 css={css`
                margin-top: 0 !important; margin-bottom: 0.75em !important; text-align: left !important; font-size: 1.3em !important;
                `}>A Dog of the Year nominee from each of the Puppy, Adult, Senior and Small categories on the Great British Dogs website have finally been chosen</h2>
        <p className="my-4" css={css`margin-bottom: 2em !important;`}>Read on to get to know the four pooches in the running to be crowned the Great British Dogs Dog of the Year 2023 and then vote for your favourite...</p>
        <h3 className="text-primary font-slab font-bold text-left pb-5 text-2xl" css={css`margin-top: 0.7em !important; margin-bottom: 0em !important; color: #004030 !important; font-size: 1.5rem !important;`}>Puppy: Meet Bruno</h3>
        <img src={hero} alt="Meet Bruno"/>
        <p css={css`font-weight: 600;`}><strong>Bruno has allowed Ellie and her partner to both overcome the loss of previous dogs</strong></p>
        <p>After losing my old dog Kai in November 2020, I was absolutely heartbroken. I couldn’t go to work, couldn’t eat or drink, my hair started to fall out – a lot. I said to myself I’d never get another dog, as I didn’t want to get heartbroken again. I met my partner in May 2021. He lost his dog in 2019 and he had the exact same problems with heartbreak.</p>
        <div class="article-quote">
        <p>He is such an <strong css={css`color: #004030 !important;`}>intelligent</strong> dog for nine months old</p>
        </div>
        <p>For our one year anniversary, my partner bought me Bruno. I was so happy, I cried for hours! Since having Bruno, he has mended my heart and fixed a piece of me that was missing. He has cured my anxiety and depression, and I'm now off my mental health medication. I was also due to have the gastric sleeve this year, but he's given me the motivation to go on long walks, and now I've lost nearly 7 stone!</p>
        <p>My partner is type 1 diabetic. Bruno magically knows when his sugars are high or low. He will jump all over me and won’t stop until I check my partner’s sugars, and would you believe it, they are either dangerously high or low. He is such an intelligent dog for nine months old. He is the best little man and he really deserves to be dog of the year or month as without him, I wouldn’t be here today. He truly is my little angel.</p>
        <p className="bottom-paragraph" css={css`color:#ae456c!important;font-style:italic;font-size:1.1rem;`}>What the judges said: Bruno’s a dog that can help one partner lose 7 stone and the other manage their diabetes, as well as healing the pain of loss - impressive.</p>
        
        <h3 className="text-primary font-slab font-bold text-left pb-5 text-2xl" css={css`margin-top: 0.7em !important; margin-bottom: 0em !important; color: #004030 !important; font-size: 1.5rem !important;`}>Small: Meet Willow</h3>
        <img src={hero2} alt="Meet Willow"/>
        <p css={css`font-weight: 600;`}><strong>Jennifer’s daughter was really struggling after she had to leave school due to her autism – but that all changed when Willow came along</strong></p>
        <p>My daughter, now 15, has been home-schooled since she was 11. For a little context, she has autism, so secondary school really took a toll on her mental health. The constant overstimulation would leave her tired for days on end. Eventually she couldn't continue at the school, so we had to take her out.</p>
        <p>About a year ago, things were getting quite bad. She was barely showering or cleaning herself, she wasn’t leaving the house due to rising anxiety, and she really wasn’t the happy little girl I know and love. She’s always had a connection with animals, and her love for them has been a coping mechanism, so we got her Willow.</p>
        <div class="article-quote">
        <p>We’re currently planning to get her <strong css={css`color: #004030 !important;`}>trained to be an assistance dog</strong> for my daughter</p>
        </div>
        <p>To say this bettered her life is a massive understatement. Willow is a playful pooch. She’ll bring you a toy anytime to try to get you to play. She loves to play tug with anything. She likes it when we sit on the floor and throw dog biscuits for her to chase and eat. She also enjoys bringing us bones to hold while she chews them – it's often stinky, but it's how she bonds, and we love her for it! She also loves her cuddles, so she’ll curl up on our laps and sleep whenever possible.</p>
        <p>Having the extra responsibility of this fluffball in my daughter’s life has helped tremendously. She now leaves the house to walk her, she comes out of her room more to spend time with Willow, and she’s even more confident talking to people with Willow nearby! We’re currently planning to get her trained to be an assistance dog for my daughter. She’s the little superhero we needed.</p>
        <p className="bottom-paragraph" css={css`color:#ae456c!important;font-style:italic;font-size:1.1rem;`}>What the judges said: Thanks to having responsibility for her playful dog Willow, Jennifer’s 15-year-old autistic daughter has gone from anxious, reclusive and self-neglectful to confident enough to take Willow out on walks and even to talk to visitors.</p>
        <h3 className="text-primary font-slab font-bold text-left pb-5 text-2xl" css={css`margin-top: 0.7em !important; margin-bottom: 0em !important; color: #004030 !important; font-size: 1.5rem !important;`}>Adult: Meet Dora</h3>
        <img src={hero3} alt="Meet Dora"/>
        <p css={css`font-weight: 600;`}><strong>After being rescued from her abusive trainer, life has only got better for Dora since she joined Claire’s family</strong></p>
        <p>Dora was rescued by the RSPCA in September 2022, and came into our lives when we adopted her in May 2023. She had survived starvation and neglect at the hands of her trainer, who was sentenced to 32 weeks in prison and given a lifetime ban from owning animals.</p>
        <p>Dora is a sweet, bright and affectionate baby. She’s quite the comedian and wakes us up every morning by howling in our faces. She loves to 'curtsy' to greet people when they come into the house. She lightens up our work calls by laying on her back behind us and pulling goofy faces.</p>
        <div class="article-quote">
        <p>I want to give her as many <strong css={css`color: #004030 !important;`}>experiences as I can</strong> to make up for her life before</p>
        </div>
        <p>Her favourite trick at the moment is to jump as high as she can when we leave the house on our walks. She also likes to outrun her best friend, Jadzia the German Shepherd, and play chase with her.</p>
        <p>To me, Dora is an inspiration. She’s a reminder than no matter what you go through in life, you can't stop and wallow in misery. She’s a brave girl who genuinely loves humans and craves attention and affection. She makes my life better and I love thinking about the happy days I will give her.</p>
        <p>She inspires me to look after myself, so that I can look after her. I’ve been to places I never would have gone, just because I want to give her as many experiences as I can to make up for her life before. She’s the best decision I've ever made.</p>
        <p className="bottom-paragraph" css={css`color:#ae456c!important;font-style:italic;font-size:1.1rem;`}>What the judges said: An energetic dog who’s a reminder than no matter what you go through in life, you can't wallow – and comical Dora howls for attention, curtseys to visitors and pulls goofy faces.</p>
        
        <h3 className="text-primary font-slab font-bold text-left pb-5 text-2xl" css={css`margin-top: 0.7em !important; margin-bottom: 0em !important; color: #004030 !important; font-size: 1.5rem !important;`}>Senior: Meet Scout</h3>
        <img src={hero4} alt="Meet Scout"/>
        <p css={css`font-weight: 600;`}><strong>Ever since Scout’s life was saved as a puppy, Tracey has been working hard to put that kindness back out into the world</strong></p>
        <p>I didn’t know how much I needed Scout until he crash-landed (literally) into my arms as a puppy. Abandoned on the streets and left to fend for himself, he was eventually picked up and taken to a pound. He was emaciated, suffering from mange and kennel cough, and believed to be both blind and deaf, so they thought it might be kinder to put him to sleep. After all, who would want a puppy with so many problems?</p>
        <p>Luckily, thanks to the heroic efforts of three amazing rescue organisations, Scout’s life was saved. We were lucky enough to adopt him a short time later and he turned our world upside down. Although he’s not deaf, he’s completely blind, but this hasn’t held him back in the slightest. He climbs mountains, paddles in the sea and runs freely with his canine pals.</p>
        <div class="article-quote">
        <p>He always puts a <strong css={css`color: #004030 !important;`}>smile on people’s faces</strong></p>
        </div>
        <p>Throughout Scout’s life, we’ve been on a mission to repay the kindness that was shown to him as a puppy. For instance, he’s been a much-loved therapy dog, visiting a local dementia care home to spread joy and love. He’s also the adopted mascot for a local community carers café.</p>
        <p>Over the years, Scout has helped to raise thousands of pounds for a local dog rescue, attending meet and greets, street collections, and lending a paw on tombola stalls. During lockdown, he managed to raise £500 by fronting an online dog show. He’s also attended Crufts several times, supporting anti-puppy farming campaigns, and even helping to launch a charity foundation.</p>
        <p>Scout gave me my life back. After my first dog was tragically killed by another dog on a walk, I developed severe anxiety about walking outside, but owning Scout made me strong. I’m his eyes, so he needs me to be confident and unafraid, and keep him safe. Out and about, he always puts a smile on people’s faces with his quirky high-stepping gait – a result of his blindness. Scout is my inspiration, my hero and my best friend.</p>
        <p className="bottom-paragraph" css={css`color:#ae456c!important;font-style:italic;font-size:1.1rem;`}>What the judges said: Being a blind dog, Scout has obliged his owner to overcome her anxiety and develop the confidence to become his ‘eyes’ so he (and she) can lead an active and fulfilling life.</p>
        <br></br>
      </div>
    </div>
    </div>
    <div className="bg-pale-100">
        <div className="container" css={css`padding-right:0; padding-left:0;`}>
          <ReusableTiles />
        </div>
    </div>
    <Footer></Footer>
  </div>

  // </Layout>
);

export default Shortlist2023;
